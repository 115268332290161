import { FC } from 'react';

import { MicroCopy, Search, GTM } from '@vakantiesnl/types';

import { FetchedProductCard } from './FetchedProductCard';
import { SkeletonProductCard } from './ProductCard';

type ProductCardProps = {
	microCopies: MicroCopy;
	accommodation: Search.CompactAccommodationItem | number;
	index: number;
	pageType?: GTM.GTMPageType;
	needQueryParams?: boolean;
};

export const ProductCard: FC<ProductCardProps> = ({
	accommodation,
	index,
	microCopies,
	pageType,
	needQueryParams = true,
}) => {
	if (typeof accommodation === 'number' || !accommodation.offers[0] || !accommodation) {
		return <SkeletonProductCard key={index} hasError={typeof accommodation !== 'number'} />;
	}

	return (
		<FetchedProductCard
			index={index}
			accommodation={accommodation}
			microCopies={microCopies}
			pageType={pageType}
			needQueryParams={needQueryParams}
		/>
	);
};
