import { FC, memo } from 'react';

import { Card } from '@vakantiesnl/components/src/__LEGACY__/atoms/Card';
import classNames from 'classnames';

import styles from './ProductCard.module.css';

type Props = {
	hasError: boolean;
};

const SkeletonProductCard: FC<Props> = ({ hasError = false }) => (
	<Card className={classNames(styles.skeletonProductCard, hasError && styles.hasError)}>
		<div className={styles.cardHeader} />
		<div className={styles.cardContext}>
			<div className={styles.hotelTitle}>
				<div />
			</div>
			<div className={styles.location} />
			<div className={styles.button} />
			<div className={styles.calamitiesText} />
		</div>
	</Card>
);

const memoizedSkeletonProductCard = memo(SkeletonProductCard);
export { memoizedSkeletonProductCard as SkeletonProductCard };
