import { FC, memo } from 'react';

import { Icon, IconTypes } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';

import useStyles from './Label.style';

export type Variant =
	| 'gold'
	| 'silver'
	| 'orange'
	| 'aspect'
	| 'merchandisingOrange'
	| 'emerald'
	| 'darkEmerald'
	| 'green'
	| 'reversedOrange';

export type LabelProps = {
	/** Text to show in the label */
	title: string;
	/** Variant of the label */
	variant: Variant;
	/** Icon of the label */
	icon?: IconTypes;
	/** Custom classname */
	className?: string;
	/** Whether the label should show lowercase (uppercase is default) */
	lowercase?: boolean;
};

const Label: FC<LabelProps> = ({ className, title, variant, icon, lowercase }) => {
	const { classes: styles, cx } = useStyles();
	return (
		<div
			data-cy="Label"
			className={cx(
				styles.label,
				{
					[styles.gold]: variant === 'gold',
					[styles.silver]: variant === 'silver',
					[styles.aspect]: variant === 'aspect',
					[styles.merchandisingOrange]: variant === 'merchandisingOrange',
					[styles.emerald]: variant === 'emerald',
					[styles.darkEmerald]: variant === 'darkEmerald',
					[styles.reversedOrange]: variant === 'reversedOrange',
					[styles.green]: variant === 'green',
					[styles.orange]: variant === 'orange',
				},
				lowercase ? styles.lowercase : styles.uppercase,
				icon && styles.withIcon,
				className,
			)}
		>
			<div className={styles.content}>
				<span className={styles.title}>{title}</span>
				{icon && (
					<div className={styles.icon}>
						<Icon type={icon} />
					</div>
				)}
			</div>
		</div>
	);
};

const memoizedLabel = memo(Label);
export { memoizedLabel as Label };
