import { FC, ReactNode } from 'react';

import { useCustomStyles } from './Disclaimer.style';

export type DisclaimerProps = {
	children: ReactNode;
	textAlign?: 'left' | 'right';
	className?: string;
};

export const Disclaimer: FC<DisclaimerProps> = ({ children, textAlign = 'left', className }): JSX.Element => {
	const { classes, cx } = useCustomStyles();

	return <p className={cx(classes.disclaimer, classes[textAlign], className)}>{children}</p>;
};
