import { alpha } from '@mui/material/styles';
import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';
import { Theme as BrandTheme } from '@vakantiesnl/types';

const useStyles = deprecatedMakeStyles<void, 'lowercase' | 'uppercase' | 'title'>()((theme, _, classes) => ({
	label: {
		borderRadius: theme.themeName === BrandTheme.zvrnl ? theme.shape.borderRadius / 2 : theme.shape.borderRadius,
		border: theme.borders.white,
		color: theme.palette?.common?.white,
		padding: `6px ${theme.spacing(1)}`,
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: 400,
		fontSize: '0.7rem',
		display: 'table',

		[`&.${classes.lowercase}`]: {
			textTransform: 'none',
		},
		[`&.${classes.uppercase}`]: {
			textTransform: 'uppercase',
		},
		[theme.breakpoints.only('sm')]: {
			padding: '5px 6px',
		},
	},
	content: {
		display: 'flex',
		height: '16px',
		width: '100%',
		alignItems: 'center',
	},
	withIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: theme.shape.borderRadius - 2,
		fontSize: '14px',

		[`& .${classes.title}`]: {
			marginRight: theme.spacing(1),
		},
	},
	title: {},
	aspect: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.grey[900],
		border: theme.borders.dark,
		borderRadius: theme.shape.borderRadius - 2,
		fontSize: '14px',
	},
	orange: {
		backgroundColor: theme.palette.warning.light,
	},
	merchandisingOrange: {
		backgroundColor: theme.palette?.merchandise?.main,
	},
	emerald: {
		backgroundColor: theme.palette?.zooverOrange?.light || theme.palette?.emerald?.main,
		color: theme.palette?.zooverOrange?.main,
	},
	darkEmerald: {
		backgroundColor: theme.palette?.emerald?.dark,
	},
	green: {
		backgroundColor:
			theme.themeName === BrandTheme.zvrnl ? alpha(theme.palette.success.dark, 0.1) : theme.palette.success.light,
		color: theme.themeName === BrandTheme.zvrnl ? theme.palette.success.dark : theme.palette.common.white,
	},
	reversedOrange: {
		borderColor: theme.palette.warning.light,
		color: theme.palette.warning.light,
	},
	gold: {
		border: `1px solid ${theme.palette?.awards?.main}`,
		background: theme?.gradients?.gold,
		fontWeight: theme.typography.fontWeightBold as number,
	},
	silver: {
		border: `1px solid ${theme.palette?.awards?.secondary}`,
		background: theme?.gradients?.silver,
		fontWeight: theme.typography.fontWeightBold as number,
	},
	lowercase: {
		textTransform: 'lowercase',
	},
	uppercase: {},
	icon: {
		width: '12px',

		'& svg': {
			width: '100%',
			height: '100%',
		},
	},
}));

export default useStyles;
