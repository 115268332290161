import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles()((theme) => ({
	disclaimer: {
		color: theme.disclaimer.color,
		font: theme.disclaimer.font,
		margin: 0,
	},
	left: {
		textAlign: 'left',
	},
	right: {
		textAlign: 'right',
	},
}));
