import { useState, useEffect } from 'react';

export const throttle = <T extends []>(callback: (..._: T) => void, wait: number): ((..._: T) => void) => {
	let timeout: NodeJS.Timeout | undefined;
	let lastArgs: T;
	const next = (): void => {
		timeout = timeout && (clearTimeout(timeout) as undefined);
		callback(...lastArgs);
	};

	return (...args: T): void => {
		lastArgs = args;

		if (timeout === void 0) {
			timeout = setTimeout(next, wait);
		}
	};
};

const getDeviceConfig = (query: number): boolean => {
	// em value 48 for mobile and 64 for tablet
	const value = window.innerWidth / parseFloat(getComputedStyle(document.body).fontSize);
	return value < query;
};

const useBreakpoint = (query: number): boolean => {
	const [value, setValue] = useState<boolean>(() => (typeof window !== 'undefined' ? getDeviceConfig(query) : false));

	useEffect(() => {
		let mounted = true;
		const calcInnerWidth = throttle(function () {
			if (mounted) {
				setValue(getDeviceConfig(query));
			}
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return (): void => {
			window.removeEventListener('resize', calcInnerWidth);
			mounted = false;
		};
	}, [query]);

	return value;
};

export default useBreakpoint;

export const useSmallBreakpoint = (): boolean => useBreakpoint(30);

export const useIsMobile = (): boolean => useBreakpoint(48);

export const useIsTablet = (): boolean => useBreakpoint(64);
