import { FC, memo } from 'react';

import useStyles from './GeoBreadcrumb.style';

type Props = {
	country: string;
	region: string;
	city: string;
	className?: string;
};

const GeoBreadcrumb: FC<Props> = ({ country, region, city, className }) => {
	const { classes: styles, cx } = useStyles();
	return (
		<div className={cx(styles.geoBreadcrumb, className)}>
			<span className={styles.geoCountry}>{country}</span> <span className={styles.geoDivider}>/</span>{' '}
			<span className={styles.geoRegion}>{region}</span> <span className={styles.geoDivider}>/</span>{' '}
			<span className={styles.geoCity}>{city}</span>
		</div>
	);
};

export default memo(GeoBreadcrumb);
