import { FC, memo } from 'react';

import { Icon, IconTypes } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';
import classNames from 'classnames';

import styles from './Chip.module.css';

type Props = {
	icon: IconTypes;
	title: string;
	label?: string;
	labelColor?: 'danger';
	note?: string;
};

const Chip: FC<Props> = ({ icon, title, label, labelColor, note }) => (
	<div className={styles.chip}>
		<Icon className={classNames(styles.icon, styles[icon])} type={icon} />
		{label && (
			<span className={classNames(styles.label, labelColor && styles['label--' + labelColor])}>{label}</span>
		)}
		{title}
		{note && <span className={styles.note}>{note}</span>}
	</div>
);

const memoizedChip = memo(Chip);
export { memoizedChip as Chip };
