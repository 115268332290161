import { getFormattedPrice } from '@vakantiesnl/services/src/util/mathUtils';
import { inject } from '@vakantiesnl/services/src/util/template';
import { Booking, MicroCopy } from '@vakantiesnl/types';

export const generateDisclaimerPriceMessage = (
	extras: Pick<Booking.Extras, 'surcharges' | 'funds'>,
	microCopies: MicroCopy,
	includingCosts = false,
): string => {
	const calamiteitenfondsItem = extras.funds.items.find((item) => item.type === 'EMERGENCY_FUNDS');
	const calamiteitenfondsPrice = (calamiteitenfondsItem?.price ?? 0) / 100;
	const formattedCalamiteitenfondsPrice = getFormattedPrice(calamiteitenfondsPrice * 100);

	const SGRItem = extras.funds.items.find((item) => item.type === 'SGR_FUNDS');
	const SGRPrice = (SGRItem?.price ?? 0) / 100;
	const formattedSGRPrice = getFormattedPrice(SGRPrice * 100);

	const bookingCostItem = extras.surcharges.items.find((item) => item.type === 'BOOKINGCOST');
	const bookingCostPrice = (bookingCostItem?.price ?? 0) / 100;
	const formattedBookingCostPrice = getFormattedPrice(bookingCostPrice * 100);

	function getInjectionParameters(): string {
		let message;
		switch (true) {
			case bookingCostPrice > 0 && calamiteitenfondsPrice > 0 && SGRPrice > 0:
				message = inject(microCopies['product.subTextBoekingskostenAndSGRAndCalamiteitenfonds'], {
					inclOrExcl: includingCosts ? microCopies['common.incl'] : microCopies['common.excl'],
					bookingCost: formattedBookingCostPrice,
					SGRPrice: formattedSGRPrice,
					calamiteitenfondsPrice: formattedCalamiteitenfondsPrice,
				});
				break;
			case bookingCostPrice > 0 && calamiteitenfondsPrice > 0 && SGRPrice <= 0:
				message = inject(microCopies['product.subTextBoekingskostenAndCalamiteitenfonds'], {
					inclOrExcl: includingCosts ? microCopies['common.incl'] : microCopies['common.excl'],
					bookingCost: formattedBookingCostPrice,
					calamiteitenfondsPrice: formattedCalamiteitenfondsPrice,
				});
				break;
			case bookingCostPrice > 0 && calamiteitenfondsPrice <= 0 && SGRPrice > 0:
				message = inject(microCopies['product.subTextBoekingskostenAndSGR'], {
					inclOrExcl: includingCosts ? microCopies['common.incl'] : microCopies['common.excl'],
					bookingCost: formattedBookingCostPrice,
					SGRPrice: formattedSGRPrice,
				});
				break;
			case bookingCostPrice > 0 && calamiteitenfondsPrice <= 0 && SGRPrice <= 0:
				message = inject(microCopies['product.subTextBoekingskosten'], {
					inclOrExcl: includingCosts ? microCopies['common.incl'] : microCopies['common.excl'],
					bookingCost: formattedBookingCostPrice,
				});
				break;
			case bookingCostPrice === 0 && calamiteitenfondsPrice > 0 && SGRPrice > 0:
				message = inject(microCopies['product.subTextSGRAndEmergencyFund'], {
					inclOrExcl: includingCosts ? microCopies['common.incl'] : microCopies['common.excl'],
					SGRPrice: formattedSGRPrice,
					calamiteitenfondsPrice: formattedCalamiteitenfondsPrice,
				});
				break;
			case bookingCostPrice === 0 && calamiteitenfondsPrice > 0 && SGRPrice <= 0:
				message = inject(microCopies['product.subTextEmergencyFund'], {
					inclOrExcl: includingCosts ? microCopies['common.incl'] : microCopies['common.excl'],
					calamiteitenfondsPrice: formattedCalamiteitenfondsPrice,
				});
				break;
			case bookingCostPrice === 0 && SGRPrice > 0 && calamiteitenfondsPrice <= 0:
				message = inject(microCopies['product.subTextSGR'], {
					inclOrExcl: includingCosts ? microCopies['common.incl'] : microCopies['common.excl'],
					SGRPrice: formattedSGRPrice,
				});
				break;
			default:
				message = microCopies['product.generalSubText'];
		}
		return Array.isArray(message) ? message.join('') : message;
	}

	return getInjectionParameters();
};
