import { FC, useMemo } from 'react';

import { Button } from '@vakantiesnl/components/src/__LEGACY__/atoms/Button';
import { Card } from '@vakantiesnl/components/src/__LEGACY__/atoms/Card';
import { Chip } from '@vakantiesnl/components/src/__LEGACY__/atoms/Chip';
import { GeoBreadcrumb } from '@vakantiesnl/components/src/__LEGACY__/atoms/GeoBreadcrumb';
import { Heading } from '@vakantiesnl/components/src/__LEGACY__/atoms/Heading';
import { ImageWithRating } from '@vakantiesnl/components/src/__LEGACY__/atoms/ImageWithRating';
import { Label } from '@vakantiesnl/components/src/__LEGACY__/atoms/Label';
import { StarRating } from '@vakantiesnl/components/src/__LEGACY__/atoms/StarRating';
import { Disclaimer } from '@vakantiesnl/components/src/atoms/Disclaimer';
import { generateDisclaimerPriceMessage } from '@vakantiesnl/components/src/utils/disclaimerPriceMessage';
import { mapAirportName } from '@vakantiesnl/services/src/contentful/mapper';
import { useIsMobile } from '@vakantiesnl/services/src/hooks/useMediaQuery';
import { useGetDaysText, useGetNightsText } from '@vakantiesnl/services/src/hooks/utils/hook';
import { isPackageOffer } from '@vakantiesnl/services/src/util';
import { getPeriodStayString } from '@vakantiesnl/services/src/util/dateUtils';
import { Search, Nbc, MicroCopy } from '@vakantiesnl/types';
import cls from 'classnames';
import { isUndefined } from 'lodash';

import accommodationFallbackImage from './assets/placeholder.svg';
import styles from './ProductCard.module.css';

type ProductCardProps = {
	accommodation: Search.CompactAccommodationItem;
	microCopies: MicroCopy;
};

// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
const labelColor: {
	[key in Nbc.MerchandisingType]:
		| 'merchandisingOrange'
		| 'orange'
		| 'emerald'
		| 'darkEmerald'
		| 'green'
		| 'reversedOrange';
} = {
	bestseller: 'merchandisingOrange',
	best_reviewed: 'merchandisingOrange',
	boutique: 'emerald',
	adult_only: 'emerald',
	aqua_park: 'emerald',
	child_friendly: 'emerald',
	budget: 'green',
	luxe: 'darkEmerald',
	city_trip: 'emerald',
	distant_destination: 'emerald',
	all_inclusive: 'darkEmerald',
	wellness: 'darkEmerald',
	sun_holiday: 'emerald',
};

export const ProductCard: FC<ProductCardProps> = ({ microCopies, accommodation }) => {
	const offer = accommodation.offers[0];

	const daysText = useGetDaysText(offer?.durationDays, useIsMobile(), microCopies);
	const nightsText = useGetNightsText(offer?.durationNights, microCopies);

	const imgSrc: string | undefined = useMemo(() => {
		if (!isUndefined(accommodation.previewImage) && accommodation.previewImage?.length > 0)
			return accommodation.previewImage;
		return undefined;
	}, [accommodation.previewImage]);

	// This card is only used on Vakanties where we only serve package acco's with flights
	if (!offer || !isPackageOffer(offer)) return null;

	return (
		<Card className={styles.card}>
			{(!isUndefined(imgSrc) || !isUndefined(accommodationFallbackImage)) && (
				<ImageWithRating
					microCopies={microCopies}
					imageSrc={imgSrc ? imgSrc : accommodationFallbackImage}
					imageClassName={cls(styles.flexImg, {
						[styles.placeholder]: !imgSrc && accommodationFallbackImage,
					})}
					reviewCount={accommodation.reviewCount}
					reviewScore={Number(accommodation.rating)}
					alt={`${accommodation.slug}-1`}
					sizes="40vw"
				/>
			)}
			<div className={styles.cardContext}>
				{accommodation.merchandisingLabel && (
					<div>
						<Label
							title={microCopies[`theme_label.${accommodation.merchandisingLabel}`]}
							variant={labelColor[accommodation.merchandisingLabel]}
							className={styles.label}
						/>
					</div>
				)}
				<Heading className={styles.hotelTitle} headingLevel="h3">
					<span className={styles.hotelTitleName}>{accommodation.name}</span>
					{accommodation.starRating && (
						<span>
							<StarRating starCount={accommodation.starRating || 1} />
						</span>
					)}
				</Heading>

				<GeoBreadcrumb
					country={accommodation.country}
					region={accommodation.region}
					city={accommodation.city}
				/>

				<div className={styles.chipsContainer}>
					<Chip
						icon="vaknlDeparture"
						title={getPeriodStayString(
							new Date(offer.outbound.departureDateTime),
							offer.durationNights,
							microCopies,
						)}
						note={'(' + daysText + ', ' + nightsText + ')'}
					/>
					<Chip
						icon="vaknlOutboundFlight"
						title={`${microCopies['tripdata.from'].toLowerCase()} ${mapAirportName(
							offer.outbound.departureAirport,
							microCopies,
						)}`}
					/>
					<Chip icon="vaknlMealPlan" title={microCopies[`mealplan_label.${offer.rooms[0].board.code}`]} />
					{offer.rooms.some((room) => room.hasTransfer) && (
						<Chip
							icon="vaknlTransfer"
							label={`${microCopies['common.inclusive']}`}
							title={`${microCopies['common.transfer'].toLowerCase()}`}
						/>
					)}
					{offer.hasLuggageIncluded && (
						<Chip
							icon="vaknlLuggage"
							label={`${microCopies['common.inclusive']}`}
							title={`${microCopies['common.luggage'].toLowerCase()}`}
						/>
					)}
				</div>
				<div className={styles.cardFooter}>
					<Button
						title={Math.round(offer.pricePerAdult / 100).toString()}
						variant="price"
						iconRight="vaknlSleekArrowRight"
						largeIcon={true}
						microCopies={microCopies}
					/>
					<Disclaimer>{generateDisclaimerPriceMessage(offer.extras, microCopies)}</Disclaimer>
				</div>
			</div>
		</Card>
	);
};
