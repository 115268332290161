import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles()((theme) => ({
	geoBreadcrumb: {
		display: 'inline-block',
		position: 'relative',
		marginBottom: theme.spacing(2),
		fontSize: theme.typography.subtitle1.fontSize,
		fontFamily: theme.typography.body1.fontFamily,
		color: theme.palette.grey[600],
	},
	geoCountry: {
		display: 'inline-block',
		maxWidth: '30%',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		verticalAlign: 'middle',
	},
	geoRegion: {
		display: 'inline-block',
		maxWidth: '30%',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		verticalAlign: 'middle',
	},
	geoCity: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	geoDivider: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
}));

export default useStyles;
